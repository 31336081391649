import React, { createRef, useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import header from '../../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polygon,
  OverlayView,
  InfoWindow,
} from '@react-google-maps/api'
import paths from '../../data/gmpolygon.json'
import diffusion from '../../data/data-july-2024.json'

import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  InputModal,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import {
  faCheck,
  faMinus,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import LinkBack from '../../components/link-back'

const DiffusionTubesIndex = ({ location }) => {
  const [showModal1, setShowModal1] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [year, setYear] = useState('2023')
  const [scheme, setScheme] = useState('CAP') //CAP //LAQM
  const [center, setCenter] = useState({
    lat: 53.5151979,
    lng: -2.35003194,
  })
  const [tempCenter, setTempCenter] = useState({
    lat: 53.5151979,
    lng: -2.35003194,
  })
  const [zoom, setZoom] = useState(10)

  useEffect(() => {
    const sess = cookies.get('ca-dt') || {
      year: '2023',
      scheme: 'CAP',
      center: {
        lat: 53.5151979,
        lng: -2.35003194,
      },
      zoom: 10,
    }

    setScheme(sess.scheme)
    setYear(sess.year)

    setTimeout(() => {
      setCenter(sess.center)
      setZoom(sess.zoom)
    }, 1000)
  }, [])

  const [markerRefs, setMarkerRefs] = React.useState([])
  const infoWindow = useRef()
  const years = ['2018', '2019', '2020', '2021', '2022', '2023']
  const siteTitle = 'Clean Air Greater Manchester'

  const options = {
    fillColor: 'black',
    fillOpacity: 0.1,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  }

  const thresholdsCAP = [
    {
      pin: 'map1.png',
      below: 30,
      above: 0,
      hex: '#34FE07',
      text: '30 or below',
      desc: 'Very compliant (green)',
      zIndex: 10,
    },
    {
      pin: 'map2.png',
      below: 35,
      above: 30,
      hex: '#FDFC03',
      text: 'Above 30 to 35',
      desc: 'Compliant (yellow)',
      zIndex: 20,
    },
    {
      pin: 'map3.png',
      below: 40,
      above: 35,
      hex: '#FF9A02',
      text: 'Above 35 to 40',
      desc: 'At risk of exceeding legal limit (amber)',
      zIndex: 30,
    },
    {
      pin: 'map4.png',
      below: 60,
      above: 40,
      hex: '#FF0000',
      text: 'Above 40 to 60',
      desc: 'Above legal limit (red)',
      zIndex: 40,
    },
    {
      pin: 'map5.png',
      below: 1000,
      above: 60,
      hex: '#CE2FFF',
      text: 'Above 60',
      desc: 'Well above legal limit (purple)',
      zIndex: 50,
    },
  ]

  const thresholdsLAQM = [
    {
      pin: 'map1.png',
      below: 30,
      above: 0,
      hex: '#34FE07',
      text: 'Below 30',
      desc: 'Very compliant (green)',
      zIndex: 10,
    },
    {
      pin: 'map2.png',
      below: 35,
      above: 30,
      hex: '#FDFC03',
      text: '30 to below 35',
      desc: 'Compliant (yellow)',
      zIndex: 20,
    },
    {
      pin: 'map3.png',
      below: 40,
      above: 35,
      hex: '#FF9A02',
      text: '35 to below 40',
      desc: 'At risk of exceeding legal limit (amber)',
      zIndex: 30,
    },
    {
      pin: 'map4.png',
      below: 60,
      above: 40,
      hex: '#FF0000',
      text: '40 to below 60',
      desc: 'Above legal limit (red)',
      zIndex: 40,
    },
    {
      pin: 'map5.png',
      below: 1000,
      above: 60,
      hex: '#CE2FFF',
      text: '60 and above',
      desc: 'Well above legal limit (purple)',
      zIndex: 50,
    },
  ]

  const getThresholdObj = value => {
    let v = parseFloat(value)

    // special compensation
    // as government say 40.5 or under is less than 40
    let thresholds
    if (scheme === 'CAP') {
      thresholds = thresholdsCAP
      if (v > 40 && v < 40.5) {
        v = 39.9
      }

      const obj = thresholds.find(t => v >= t.above && v < t.below)
      return obj
    }

    if (scheme === 'LAQM') {
      thresholds = thresholdsLAQM

      const obj = thresholds.find(t => v > t.above && v <= t.below)
      return obj
    }
  }

  const centerOverlayView = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  })

  const onLoad = map => {
    map.addListener('zoom_changed', () => {
      console.log(map.zoom)
      setZoom(map.zoom)
    })
    map.addListener('center_changed', () => {
      console.log('center changed')
      setTempCenter({
        lat: map.center.lat(),
        lng: map.center.lng(),
      })
    })
  }

  useEffect(() => {
    // add or remove refs
    setMarkerRefs(markerRefs =>
      Array(diffusion.length)
        .fill()
        .map((_, i) => markerRefs[i] || createRef())
    )
  }, [])

  return (
    <Layout location={location}>
      <div>
        <Helmet title={'Diffusion Tubes | ' + siteTitle} />
        <div className="page-banner page-banner--disclaimer">
          <img className="d-none d-lg-block" src={header} alt="" />
          <img className="d-block d-lg-none" src={headerMobile} alt="" />
          <h1>Diffusion tube data</h1>
        </div>
      </div>
      <Container>
        <LinkBack to={'/data-hub'} title={'back to Data hub'} />
        <h2>Greater Manchester's air quality data</h2>
        <p>
          Click on the maps below to see the locations of hundreds of air
          quality monitoring diffusion tubes across Greater Manchester. These
          are used to monitor the amount of harmful nitrogen dioxide (NO
          <sub>2</sub>) in the air.
        </p>
        <p>
          We collect and analyse two different sets of data and you can toggle
          between them using the buttons below:
        </p>
        <ul>
          <li>
            <p className="mb-2">
              <button
                style={{ margin: 0, padding: 0, marginRight: '3px' }}
                className="btn-link btn"
                onClick={() => {
                  setShowModal1(true)
                }}
              >
                Clean Air Plan Data:
              </button>
              This map shows data collected largely at busy roadside locations
              for the purposes of the Clean Air Plan. Nitrogen dioxide levels
              are measured against the long-term annual mean legal limit of
              40μg/m<sup>3</sup>. For Clean Air Plan monitoring purposes,
              government criteria are that measurements at diffusion tube
              concentrations of 40.4μg/m<sup>3</sup> and below are rounded down
              to 40μg/m<sup>3</sup> and are within the annual mean legal limit.
            </p>
          </li>
          <li>
            <p className="mb-2">
              <button
                style={{ margin: 0, padding: 0, marginRight: '3px' }}
                className="btn-link btn"
                onClick={() => {
                  setShowModal2(true)
                }}
              >
                Local Air Quality Management Data:
              </button>
              This map shows data collected for the purposes of Local Air
              Quality Management. Monitoring takes place in areas where there is
              relevant public exposure to pollution, and includes locations such
              as homes, schools, hospitals and care homes. For Local Air Quality
              Management purposes, government criteria are that measurements at
              diffusion tube concentrations of 40.0μg/m<sup>3</sup> and over are
              above the annual mean legal limit.
            </p>
          </li>
        </ul>
        <p>
          Nitrogen dioxide levels are measured against both the long-term annual
          mean legal limit of 40μg/m<sup>3</sup> and a short-term hourly legal
          limit of 200μg/m<sup>3</sup>. Exposure to nitrogen dioxide has a
          cumulative impact over time and may contribute to chronic health
          conditions – similar to smoking.
        </p>
        {/* <p style={{ fontSize: '12px' }}>
          <sup>1</sup>
          All locations where members of the public might be regularly exposed.
          Building façades of residential properties, schools, hospitals, care
          homes etc. Kerbside locations are on the whole excluded, unless
          members of the public are likely to be exposed for longer than the
          time used to determine the legal limit for the pollutant concerned.
          Box 1.1 for TG16 give more detail{' '}
          <a
            target="_blank"
            href="https://laqm.defra.gov.uk/documents/LAQM-TG16-April-21-v1.pdf"
          >
            LAQM-TG16-April-21-v1.pdf (defra.gov.uk)
          </a>
          <br />
          <br />
          <sup>2</sup> The duty to designate those areas where air quality
          objectives are not being met comes from s83 Environment Act 1995.
        </p> */}

        <h3>How we use this data</h3>
        <p>
          Diffusion tube data is used to assess levels of NO<sub>2</sub> in the
          air in Greater Manchester and for scientific modelling to predict
          future levels of NO<sub>2</sub>. Monitored air quality data is used to
          review and align the Clean Air Plan modelling which is validated by
          the government’s Joint Air Quality Unit independent review panel.
        </p>

        <div
          aria-hidden="true"
          tabindex="-1"
          aria-label="Map showing diffusion tube data in Greater Manchester."
          id="map"
          style={{ height: '500px' }}
          className="mt-5 mb-2"
        >
          <LoadScript
            id="script-loader"
            googleMapsApiKey="AIzaSyA1yf9mWk13ntuoG5Lia0P-i3UH_igYi-A"
          >
            <GoogleMap
              className="map"
              id="diffusionMap"
              onLoad={onLoad}
              mapContainerStyle={{
                height: '500px',
                width: '100%',
              }}
              zoom={zoom}
              options={{
                maxZoom: 15,
              }}
              center={center}
            >
              <Polygon paths={paths} options={options} />
              {diffusion.map((d, i) => {
                let pin = 'map1.png'
                let zIndex = 10
                let visible = false

                const value = d[`${year}c`]

                if (
                  !(
                    value === undefined ||
                    d.Survey !== scheme ||
                    value.length === 0
                  )
                ) {
                  visible = true
                  const obj = getThresholdObj(value)
                  if (!obj) {
                    console.log('objerror', d)
                  }
                  pin = obj.pin
                  zIndex = obj.zIndex
                }

                return (
                  <Marker
                    icon={`/images/${pin}`}
                    data={d}
                    ref={markerRefs[i]}
                    position={{ lat: d.Lat, lng: d.Lon }}
                    visible={visible}
                    title={`${d.Ref}`}
                    clickable
                    opacity={0.8}
                    zIndex={zIndex}
                    onClick={() => {
                      cookies.set(
                        'ca-dt',
                        {
                          scheme,
                          year,
                          center: tempCenter,
                          zoom,
                        },
                        { path: '/' }
                      )
                      navigate(`/data-hub/diffusion-tubes/${d.Ref}`)
                    }}
                  />
                )
              })}
            </GoogleMap>
          </LoadScript>
        </div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: '15px',
            fontWeight: 'bold',
          }}
          className="mb-4"
        >
          <div
            className="w-100 w-md-50"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              minHeight: '50px',
            }}
          >
            <span className="mr-4">Select map data</span>
            <button
              className={`mr-4 diffusion-button ${
                scheme === 'CAP' ? 'diffusion-button--active' : ''
              }`}
              type="button"
              onClick={() => setScheme('CAP')}
            >
              Clear Air Plan
            </button>
            <button
              className={`diffusion-button ${
                scheme === 'LAQM' ? 'diffusion-button--active' : ''
              }`}
              onClick={() => setScheme('LAQM')}
              type="button"
            >
              Local Air Quality Management
            </button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              minHeight: '50px',
            }}
            className="w-100 w-md-50"
          >
            <span className="mr-4">Select year:</span>
            <Input
              style={{ width: '100px', borderColor: 'black' }}
              type="select"
              name="day"
              id="day"
              onChange={e => {
                setYear(e.target.value)
              }}
              value={year}
            >
              {years.map(y => (
                <option value={y}>{y}</option>
              ))}
            </Input>
          </div>
        </div>

        {year === '2018' && scheme === 'CAP' && (
          <div className="mb-5">
            <h3>Clean Air Plan Monitoring – 2018</h3>
            <p>
              A 12-month diffusion tube survey was commissioned at 55 monitoring
              locations in Greater Manchester. Air quality monitoring data for
              2018 showed 29 locations where there were exceedances of the
              nitrogen dioxide annual mean standard in Greater Manchester, with
              a further 13 locations considered to be at risk of exceeding the
              legal limit.
            </p>
          </div>
        )}
        {year === '2019' && scheme === 'CAP' && (
          <div className="mb-5">
            <h3>Clean Air Plan Monitoring – 2019</h3>
            <p>
              A 12-month diffusion tube survey – including additional sites
              starting from June 2019 – was carried out at 222 monitoring
              locations in Greater Manchester. Air quality monitoring data
              showed 129 locations where there were exceedances of the nitrogen
              dioxide annual mean standard in Greater Manchester, with a further
              27 locations considered to be at risk of exceeding the legal
              limit.
            </p>
          </div>
        )}
        {year === '2020' && scheme === 'CAP' && (
          <div className="mb-5">
            <h3>Clean Air Plan Monitoring – 2020</h3>
            <p>
              A 12-month diffusion tube survey was carried out at 222 monitoring
              locations in Greater Manchester. Air quality monitoring data
              showed 16 locations where there were exceedances of the nitrogen
              dioxide annual mean standard in Greater Manchester, with a further
              44 locations considered to be at risk of exceeding the legal
              limit.
            </p>
            <p>
              Travel restrictions during 2020 due to the Covid-19 pandemic led
              to reduced vehicle traffic and associated emissions, and lower
              concentrations of air pollution.
            </p>
          </div>
        )}
        {year === '2022' && scheme === 'CAP' && (
          <div className="mb-5">
            <h3>Clean Air Plan Monitoring – 2022</h3>
            <p>
              In 2022, NO<sub>2</sub> air quality monitoring for the purposes of
              the Greater Manchester Clean Air Plan was carried out at 432
              locations (210 more than 2021), with 95 sites of exceedance. A
              further 108 locations were considered to be at risk of exceedance
              <sup>*</sup>
            </p>

            <p>
              <sup>*</sup>The number of monitoring sites in the Clean Air Plan
              survey increased in 2022 to provide greater coverage. Of the
              diffusion tubes that were in place in both 2022 and 2021, there
              would have been an additional five areas of exceedance in 2022 –
              up from 45 to 50.
            </p>
          </div>
        )}

        {year === '2023' && scheme === 'CAP' && (
          <div className="mb-5">
            <h3>Clean Air Plan Monitoring – 2023</h3>
            <p>
              In 2023, NO<sub>2</sub> air quality monitoring for the purposes of
              the Greater Manchester Clean Air Plan was carried out at 248
              locations (down from 432 in 2022 *), with 64 sites of exceedance.
              A further 78 locations were considered to be at risk of exceedance
              <sup>*</sup>
            </p>

            <p>
              <sup>*</sup>There was a reduction in the number of sites from 2022
              as some had to be moved or decommissioned. These included sites
              where monitored concentrations had shown a low risk of exceeding
              the legal limit, or where vandalism meant annual mean
              concentrations could not be calculated.
            </p>
          </div>
        )}

        {year === '2023' && scheme === 'LAQM' && (
          <div className="mb-5">
            <h3>Local Air Quality Management Monitoring – 2023</h3>
            <p>
              Local authorities in Greater Manchester are required to address
              air quality in their area and monitor for pollutants including
              nitrogen dioxide to ensure compliance with the Air Quality
              (England) Regulations (2000) as amended. This data is not
              specifically used to determine compliance with Clean Air Plan
              requirements.
            </p>

            <p>
              Local Air Quality Management monitoring at 357 diffusion tube
              sites (down from 434 in 2022*) during 2023 identified 12 locations
              in Greater Manchester where nitrogen dioxide levels exceeded the
              annual mean standard.
            </p>

            <p>
              <sup>*</sup>Wigan reduced the number of diffusion tube locations
              in 2023, which had consistently recorded low concentrations of
              nitrogen dioxide and considered no longer beneficial in informing
              the LAQM. Further information can be found in the 2023 Annual
              Status Report.
            </p>
          </div>
        )}

        {year === '2022' && scheme === 'LAQM' && (
          <div className="mb-5">
            <h3>Local Air Quality Management Monitoring – 2022</h3>
            <p>
              Local authorities in Greater Manchester are required to address
              air quality in their area and monitor for pollutants including
              nitrogen dioxide to ensure compliance with the Air Quality
              (England) Regulations (2000) as amended. This data is not
              specifically used to determine compliance with Clean Air Plan
              requirements.
            </p>
            <p>
              Local Air Quality Management monitoring at 434 diffusion tube
              sites during 2022 identified 16 locations in Greater Manchester
              where nitrogen dioxide levels exceeded the annual mean standard.
            </p>
          </div>
        )}
        {year === '2021' && scheme === 'CAP' && (
          <div className="mb-5">
            <h3>Clean Air Plan Monitoring – 2021</h3>
            <p>
              A 12-month diffusion tube survey was carried out at 222 monitoring
              locations in Greater Manchester. Air quality monitoring data
              showed 45 locations where there were exceedances of the nitrogen
              dioxide annual mean standard in Greater Manchester, with a further
              45 locations considered to be at risk of exceeding the legal
              limit.
            </p>
            <p>
              Restrictions during 2021 due to the Covid-19 pandemic led to
              reduced vehicle traffic and associated emissions, and lower
              concentrations of air pollution.
            </p>
          </div>
        )}

        {year === '2021' && scheme === 'LAQM' && (
          <div className="mb-5">
            <h3>Local Air Quality Management Monitoring – 2021</h3>
            <p>
              Local authorities in Greater Manchester are required to address
              air quality in their area and monitor for pollutants including
              nitrogen dioxide to ensure compliance with the Air Quality
              (England) Regulations (2000) as amended. This data is not
              specifically used to determine compliance with Clean Air Plan
              requirements.
            </p>
            <p>
              Local Air Quality Management monitoring at 437 diffusion tube
              sites during 2021 identified 13 locations in Greater Manchester
              where nitrogen dioxide levels exceeded the annual mean standard.
            </p>
            <p>
              Restrictions during 2021 due to the Covid-19 pandemic led to
              reduced vehicle traffic and associated emissions, and lower
              concentrations of air pollution.
            </p>
          </div>
        )}

        {year === '2020' && scheme === 'LAQM' && (
          <div className="mb-5">
            <h3>Local Air Quality Management Monitoring – 2020</h3>
            <p>
              Local authorities in Greater Manchester are required to address
              air quality in their area and monitor for pollutants including
              nitrogen dioxide to ensure compliance with the Air Quality
              (England) Regulations (2000) as amended. This data is not
              specifically used to determine compliance with Clean Air Plan
              requirements.
            </p>
            <p>
              Local Air Quality Management monitoring at 406 diffusion tube
              sites during 2020 identified 2 locations in Greater Manchester
              where nitrogen dioxide levels exceeded the annual mean standard.
            </p>
            <p>
              Travel restrictions during 2020 due to the Covid-19 pandemic led
              to reduced vehicle traffic and associated emissions, and lower
              concentrations of air pollution.
            </p>
          </div>
        )}
        {year === '2019' && scheme === 'LAQM' && (
          <div className="mb-5">
            <h3>Local Air Quality Management Monitoring – 2019</h3>
            <p>
              Local authorities in Greater Manchester are required to address
              air quality in their area and monitor for pollutants including
              nitrogen dioxide to ensure compliance with the Air Quality
              (England) Regulations 2000. This data is not specifically used to
              determine compliance with Clean Air Plan requirements.
            </p>
            <p>
              Local Air Quality Management monitoring at 360 diffusion tube
              sites during 2019 identified 62 locations in Greater Manchester
              where nitrogen dioxide levels exceeded the annual mean standard.
            </p>
          </div>
        )}
        {year === '2018' && scheme === 'LAQM' && (
          <div className="mb-5">
            <h3>Local Air Quality Management Monitoring – 2018</h3>
            <p>
              Local authorities in Greater Manchester are required to address
              air quality in their area and monitor for pollutants including
              nitrogen dioxide to ensure compliance with the Air Quality
              (England) Regulations 2000. This data is not specifically used to
              determine compliance with Clean Air Plan requirements.
            </p>
            <p>
              Local Air Quality Management monitoring at 317 diffusion tube
              sites during 2018 identified 46 locations in Greater Manchester
              where nitrogen dioxide levels exceeded the annual mean standard.
            </p>
          </div>
        )}

        <div className="quality-index mb-5">
          <h2>Air quality key</h2>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {scheme === 'CAP' &&
              thresholdsCAP.map((t, i) => (
                <div key={`k${i}`} className="diffusionKey">
                  <div
                    style={{
                      backgroundColor: t.hex,
                      height: '34px',
                      width: '100%',
                      borderRadius: '10px',
                    }}
                  ></div>
                  <h2
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      marginBottom: '10px',
                      fontSize: '20px',
                      fontWeight: 'medium',
                      lineHeight: '24px',
                    }}
                  >
                    {t.text}
                  </h2>
                  <p
                    style={{
                      textAlign: 'center',
                      padding: '0px 35px',
                    }}
                  >
                    {t.desc}
                  </p>
                </div>
              ))}
            {scheme === 'LAQM' &&
              thresholdsLAQM.map((t, i) => (
                <div key={`k${i}`} className="diffusionKey">
                  <div
                    style={{
                      backgroundColor: t.hex,
                      height: '34px',
                      width: '100%',
                      borderRadius: '10px',
                    }}
                  ></div>
                  <h2
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      marginBottom: '10px',
                      fontSize: '20px',
                      fontWeight: 'medium',
                      lineHeight: '24px',
                    }}
                  >
                    {t.text}
                  </h2>
                  <p
                    style={{
                      textAlign: 'center',
                      padding: '0px 35px',
                    }}
                  >
                    {t.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </Container>
      <Modal
        fade={false}
        backdrop={'static'}
        centered
        isOpen={showModal1}
        toggle={() => setShowModal1(!showModal1)}
        contentClassName="modal1"
      >
        <ModalBody>
          <button
            className="close-button"
            onClick={() => {
              setShowModal1(false)
            }}
          >
            <FontAwesomeIcon className="close-icon" icon={faTimesCircle} />
          </button>
          <h2>Clean Air Plan Data</h2>
          <p>
            A Government direction requires local authorities in Greater
            Manchester to bring nitrogen dioxide within the limit values in the
            Air Quality Standards Regulations (2010) in the ‘shortest possible
            time’ and by no later than 2026. The legal Limit Values apply
            everywhere that the public have pedestrian access. The GM Clean Air
            Plan tackles the long term annual average standard (40μg/m
            <sup>3</sup>). Exposure to nitrogen dioxide has a cumulative impact
            over time and may contribute to chronic health conditions – similar
            to smoking. The original Greater Manchester Clean Air Plan modelling
            from 2018 was used to identify roads with poor air quality, and
            additional monitoring has been implemented at these locations.
          </p>
        </ModalBody>
      </Modal>
      <Modal
        fade={false}
        backdrop={'static'}
        centered
        isOpen={showModal2}
        toggle={() => setShowModal2(!showModal2)}
        contentClassName="modal1"
      >
        <ModalBody>
          <button
            className="close-button"
            onClick={() => {
              setShowModal2(false)
            }}
          >
            <FontAwesomeIcon className="close-icon" icon={faTimesCircle} />
          </button>
          <h2>Local Air Quality Management Data</h2>
          <p>
            In line with legislation* Greater Manchester reviewed its air
            quality and declared an Air Quality Management Area in 2016 for
            nitrogen dioxide and published an accompanying Air Quality Action
            Plan. GM authorities monitor, and assesses the improvements their
            actions have made to local air quality and report progress to
            Government annually in an{' '}
            <a
              target="_blank"
              href="https://cleanairgm.com/data-hub/monitoring-reports"
            >
              Annual Status Report
            </a>
            .
            <br /> <br />* The Air Quality (England) Regulations 2000
          </p>
        </ModalBody>
      </Modal>
    </Layout>
  )
}

export default DiffusionTubesIndex
